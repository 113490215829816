

.table {
	width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    th {
    	text-align: left;
    }
    >thead {
    	background: $table-heading-color;
    	> tr {
    		> th {
    			@include textSWC(14px, 900, #575961);
    			padding: 15px 20px;
    		}
    	} 
    }
    >tbody {
    	> tr {
    		> td {
    			@include textSWC(14px, 400, #767a82);
    			padding: 7px 20px;
    		}
    	} 
    }
}
.table-responsive {
	min-height: .01%;
	overflow-x: auto;
}