
body{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color:$text-color;
    margin:0px;
}

/*--heading css--*/
h1{
    font-size:36px;
}
h2{
    font-size:28px;
}
h3{
    font-size: 24px;
}
h4{
    font-size:20px;
}
h5{
    font-size:16px;
}
h6{
    font-size:14px;
}
h1,h2,h3,h4,h5,h6{
    font-weight: 500;
}
/*--heading css--*/

.text-center{
    text-align: center;
}
a{
    cursor: pointer;
}
/*--padding css--*/
.pad-20{
    padding:20px;
}
.pad-10{
    padding:10px;
}
/*--padding css--*/

/*-margin css--*/
.mr-10{
margin-right: 10px;
}
.mr-5{
    margin-right:5px!important;
    }
.mb-0{
    margin-bottom: 0px!important;
}

.mt-20{
    margin-top: 20px;
}
.mt-0{
    margin-top: 0px;
}
.mt-10{
    margin-top: 10px
}
.m-0{
    margin: 0
}
.ml-5{
    margin-left: 5px!important;
}
/*-margin css--*/

img{
    max-width: 100%;
}
.full-width-row{
    width: 100%; float: left;
        .left-col{
            float: left;
        }
        .right-col{
            float: right;
        }
}

mat-icon.mat-circle-icon{
    background:$icon-bg-color;
    padding: 8px!important;
    color: #fff;
}
.mat-toolbar.mat-primary{
    background: $theme-color;
}

/*--mat button css--*/
.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary{
    background: $button-color;
}
.mat-fab.mat-secondary, .mat-flat-button.mat-secondary, .mat-mini-fab.mat-secondary, .mat-raised-button.mat-secondary{
    background: $theme-color;
    color:#fff;
}
.mat-raised-button[disabled][disabled], mat-raised-button.mat-primary[disabled] {
    color: rgba(0,0,0,.6);
    background-color: rgba(0,0,0,.12);
}
    
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
    color:$button-color;
    border-color:$button-color;
    &:disabled{
        color:#ccc;
        border-color:#ccc;
    }
}
.mat-stroked-button.mat-primary {

}
.mat-stroked-button.mat-secondary{
border-radius: 0;
border-color:$theme-color;
color: $theme-color; 

}

.mat-button,.mat-stroked-button,.mat-raised-button,.mat-flat-button {
    border-radius: 0!important;
}

.mat-raised-button.mat-delete{
    background: $delete-color;
    color: #fff;
}

.mat-raised-button.mat-cancel{
    background: $theme-color;
}
.mat-dialog-title.text-center{
    text-align: center;
}
.mat-radio-button + .mat-radio-button{
    margin-left:16px;
}

button + button{
    margin-left:5px;
}
// .mat-stroked-button:not([disabled]){
//     border-color:$button-color;
// }
/*--mat button css--*/


/*--theme modification css--*/

/*---left side menu toogle css--*/
app-sidemenu-item .sidenav-dropdown-indicator.indicateOpen {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
app-sidemenu-item .sidenav-dropdown-indicator {
    transition: -webkit-transform .25s;
    transition: transform .25s;
    transition: transform .25s, -webkit-transform .25s;
}
app-sidemenu-item mat-nav-list {
    overflow: hidden;
    transition: max-height .8s  ease-in-out;/*--cubic-bezier(0.35, 0, 0.25, 1)--*/
}
/*---left side menu toogle css--*/

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 30px transparent inset;
	transition: background-color 5000s ease-in-out 0s;
 	-webkit-text-fill-color:inherit;
}
/* Change autocomplete styles in WebKit */

/*--dropdown--*/
.dropdown .card .header{
    line-height: normal;
    color:$heading-color;
}
.dropdown .content .notification .title{
    line-height: normal;
}
/*--dropdown--*/


/*--mat input form css--*/
mat-form-field{
    display:block!important;
    +.error-msg,+.has-error{
        margin-top:-14px;
        margin-bottom: 10px;
        font-size: 13px;
    }
    &.mb-0{
        .mat-form-field-wrapper{
            margin-bottom: 0;
        }
    }
}
/*--mat input form css--*/







