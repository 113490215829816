/*
Theme Name: jellyfish
*/

// typeface variables
$baseFontFamily: 'Lato', sans-serif;

// primary color variables
$blueColor:#0fadf2;
$table-heading-color:#f2f3f8;
$sidebarBgColor:#2e3640;
$successColor:#bb925c;
$dangerColor:#fc7e90;
$warningColor:#f3c31b;
$paginationColor:#91a1d0;



$icon-bg-color:#bb925c;
$theme-color: #bb925c;
$button-color:#212446 ;
$title-bg-color:#212446 ;
$text-color:#212446;
$heading-color:#212446;
$black-color:#000;
$green-color:#448f27;
$delete-color:#cf1f1f;
$grey-color:#ddd;
$orange-text:#fb4f20;

// secondary color variables

$whiteColor:#ffffff;
$darkGrayColor:#313239;
$grayColor:#414245;
$headingColor: #5c606b;
$errorColor:#f44336;


// button variables

// text sizes
$baseFontSize:14px;

// for all types of input
$all-text-inputs: (
  "input[type='color']",
  "input[type='date']",
  "input[type='datetime']",
  "input[type='datetime-local']",
  "input[type='email']",
  "input[type='month']",
  "input[type='number']",
  "input[type='password']",
  "input[type='search']",
  "input[type='tel']",
  "input[type='text']",
  "input[type='time']",
  "input[type='url']",
  "input[type='week']",
  "input:not([type])",
  "textarea",
);




