.cashier-layout{
    app-cashier-header{
     position: fixed;
    z-index: 9;
    width: 100%;
    top: 0;
    }
    .cashier-content{
        margin-top:64px;
    }
    .cashier-full-hw-layout{
        width: 100%;
        height: calc(100% - 64px);
        position: absolute;
        overflow: auto;
    }
}