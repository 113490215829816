
/*--import other css--*/
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import  "./variable.scss";
@import  "theme.scss";
@import  "../css/revugainfont.css";

@import "_partials/mixins";
// @import "_partials/variables";
@import "_partials/cashier_layout";
@import "_partials/reset";
@import "_partials/table";


/*--import other css--*/
.content {
    background: #ede2e25c;
}
.mat-card {
   
    border-radius: 0px !important;
    margin-bottom: 10px !important;
}
.rght-sidebar{
    .mat-tab-list{
        .mat-tab-label{
            min-width:inherit;
            }
    }  
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
    background: $theme-color
}

/*--right side bar toogle--*/
mat-sidenav.mat-drawer-end.mat-drawer-over{
    max-width: 350px;
        
}

.ml-5{
    margin-left: 5px!important;
}
.ml-10{
    margin-left: 10px!important;
}
.mr-10{
    margin-right: 10px;
}
.mb-20{
    margin-bottom: 20px;
}
.mb-30{
    margin-bottom: 30px;
}
.mt-30{
    margin-top: 30px;
}
.text-right{
    text-align: right;
}
.mat-list-base{
    .mat-list-item{
        .mat-line{
            &:nth-child(n+2){
                font-size: 13px;
                font-weight:400;
            }
        }
        .mat-list-text{
            h4{
                font-weight: 500;
                color:$heading-color;
                font-size:15px;
                margin:0 0 5px;
            }
        }
    }
}

.mat-toolbar-single-row{
    padding: 0!important;
}

.page-container {
    padding-left: 250px;
	min-height: 100vh;
	// @include full-width;
    // @include transition(all .2s ease);
    app-header{
        height: 64px;
        display: block;
        overflow: hidden;
        .mat-toolbar{
            width: calc(100% - 250px);
            color: #fff;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 9;
            background: #bb925c;
        }
    }

    .media-gallery{
        display: flex;
        flex-flow: wrap;
        .media-gallery-content{
            padding: 10px;
            flex: 0 1 25%;
            .image{
                width: 100%;
                height: 210px;
            }
        }
    }
}
.is-collapsed {
    .sidebar {
        width: 62px;
        .mat-expansion-indicator{
            display: none;
            opacity: 0;
        }
        .sidebar-inner {
            .sidebar-logo {
                position: relative;
                a {
                   position: absolute;
                   clip: rect(0, 48px, 52px, 0);
                   clip-path: inset(0 116px 0 0);
                   left: 21px;
                }
            }
        }
        .brand-sidebar {
            width: 62px;
            .logo-wrapper {
                a.brand-logo {
                    span.logo-text {
                        display: none;
                        opacity: 0;
                        transition: display 0s 2s, opacity 0.05s linear;
                    }
                }
                a.navbar-toggler{
                    display: none;
                    opacity: 0;
                    transition: display 0s 2s, opacity 0.05s linear;
                }
            }
        }
        .mat-expansion-panel-content{
            .mat-list-item-content{
                .menu-text-icon{
                    h3{
                        opacity: 0;
                    }
                }
            }
        }
        &:hover {
            width: 250px;
            .mat-expansion-indicator{
                display: inline-block;
                opacity: 1;
               
            } 
            .sidebar-inner {
                .sidebar-logo {
                    position: relative;
                    a {
                       position: absolute;
                       clip: rect(0, 48px, 52px, 0);
                       clip-path: inset(0 116px 0 0);
                       left: 21px;
                    }
                }
            }
            .mat-expansion-panel-content{
                .mat-list-item-content{
                    .menu-text-icon{
                        h3{
                            opacity: 1;
                        }
                    }
                }
            }
            .brand-sidebar {
                width: 250px;
                .logo-wrapper {
                    a.brand-logo {
                        span.logo-text {
                            display: inline-block;
                            opacity: 1;
                            transition: display 0s 2s, opacity 0.05s linear;
                        }
                    }
                    a.navbar-toggler{
                        display: inline-block;
                        opacity: 1;
                        transition: display 0s 2s, opacity 0.05s linear;
                    }
                }
            }
        }
    }
    .page-container {
        padding-left: 62px;
        app-header{
            .mat-toolbar{
                width: calc(100% - 62px);
            }
        }
    }
}
.mat-page-container{
padding:15px;
    *{
     box-sizing:border-box;
    }
}

.mat-page-top-header{
    margin-bottom: 15px;
    h3{ 
        margin: 0;
        font-weight: 400!important;
        color: #333;
        font-size: 21px!important;
    }
}
app-footer{
    font-size: 13px;
}


/*--mat-dialog-box--*/
.mat-dialog-box{
    position: relative!important;
    mat-dialog-container{
        padding: 0px;
    }
    .mat-dialog-title{
        background:$title-bg-color;
        color: #fff;
        font-size: 20px;
        padding: 10px 20px;
        margin: 0;
    }
   .mat-dialog-title2 {
        /* background: #efe8e8; */
        color: #333;
        font-size: 14px;
        box-shadow: 1px 1px 7px 1px #ccc;
        /* border-bottom: 1px solid #ccc; */
        padding: 10px 20px;
        margin: 0;
    }
    .mat-dialog-content{
        padding: 20px;
        margin: 0;
    }
    .mat-dialog-actions{
        padding:0 20px 20px;
        margin: 0px;
    }
    .mat-form-field{
        display: block;
    }
    .close-btn{
        position: absolute;
        right:5px;
        top:5px;
        color:#fff;

    }
    .material-icons{
        font-size: 34px;
    }
    &.full-screen-popup{
        width: auto;
        max-width: 80vw!important;
        position: absolute!important;
        top: 0;
        bottom: 0;
        left: 0;
        right:0;
        margin: auto;
            .mat-dialog-container{
                border-radius:0px;
                .mat-dialog-content{
                    max-height: calc(100vh - 54px);
                    height: calc(100vh - 54px);
                    &.pl-10{
                        padding-left:10%!important;
                    }
                    &.pr-10{
                        padding-right:10%!important;
                    }
                }
            }

            .close-btn{
                right:16px;
            }   
        .mat-dialog-actions{
            padding:0 0 20px
        }    
    }
    &.full-screen-popup-2{
        width: 100;
        max-width: 100vw!important;
        position: absolute!important;
        top: 0;
        bottom: 0;
        left: 0;
        right:0;
        margin: auto;
            .mat-dialog-container{
                border-radius:0px;
                .mat-dialog-content{
                    max-height: calc(100vh - 54px);
                    height: calc(100vh - 54px);
                    &.pl-10{
                        padding-left:10%!important;
                    }
                    &.pr-10{
                        padding-right:10%!important;
                    }
                }
            }

            .close-btn{
                right:16px;
            }   
        .mat-dialog-actions{
            padding:0 0 20px
        }    
    }
    &.mid-hr-ver-popup{
        width: 100%;
        max-width: 100vw!important;
        position: fixed!important;
        overflow: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right:0;
        margin: auto;
            .mat-dialog-container{
                background: $theme-color;
                border-radius:0px;
                    .change-password-dialogbox{
                        position: absolute;
                        max-width: 650px;
                        width: 100%;
                        padding:30px;
                        background: #fff;
                        left: 50%;
                        top:50%;
                        margin:auto;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        .change-pass-heading{
                            text-align: center;
                            img.img_logo{
                                width:180px;
                            }
                            h4{
                                margin: 20px 0 20px;
                                font-size: 18px;
                            }
                        }
                    }
            }
            .mat-dialog-actions{
                padding:0px!important;
                button.mat-primary{
                    width: 100%;
                    display: block;
                }
            }
            .close-btn{
                color: #000
            }
    }

    &.point-view{
        .mat-dialog-content{
            padding: 0px;
            max-height: calc(100vh - 54px);
            height: calc(100vh - 54px);
                .point-view-maindiv{
                    height: calc(100vh - 54px);
                }
        }
    }
}
/*--mat-dialog-box--*/

/*--authentication-page--*/
.authentication-page{
    position: fixed;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    background: $theme-color;
    display: flex;
    justify-content: center;
    align-items: center;
        .Login_box{
            max-width: 800px;
            background: #fff;
            padding: 30px;
            min-width: 350px;
        }
      
        .forgot-password-link{
            color:$black-color;
            margin-right: 20px;
            font-weight: 500;
        }
}
/*--authentication-page--*/


/*--mat border icon--*/
.mat-icon-square{
    display: inline-block;
    border: solid 1px #ccc;
    padding: 3px;
    color:$heading-color;
    margin-right: 5px;
    line-height: 1;
    vertical-align: middle;
    &.print-icon{
        color:#0d84f2;
    }
    &.pdf-icon{
        color:#f21212;
    }
    &.file-icon{
        color:#3b9b15;
    }
}
/*--mat border icon--*/

/*--table css--*/
.table-responsive{
    width: 100%;
    overflow: auto;
    background: #fff;
    box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
}
table.mat-default-table{
    width: 100%;
    border-collapse: collapse;
        td,th{
            padding:10px;
            text-align: left;
        }
        th{
            background:#e9e2e2 ;
            color:$black-color;
            border-top: solid 1px #fff;
        }
        tbody tr:not(last-child){
           border-bottom: solid 1px #ddd; 
        }
        tbody{
            tr:nth-child(even){
                background:#f8f6f6;
            }
        }
}
/*--table css--*/

.white-bg{
    background: #fff;
}
.add-shadow{
 box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
}
.theme-shadow {
    box-shadow: 2px 1px 4px #8c6135eb, -1px 1px 2px #8c6135eb;
}

/*--add review--*/

/*--left and right side bar menu--*/
app-sidemenu{
	bottom: 0;
	overflow: hidden;
	position: fixed;
	top: 0;
	width: 250px;
	z-index: 1000;
    &.sidebar {
        background: #fff;
        width: 250px;
        overflow: hidden;
        transform: none;
        visibility: visible;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        top: 64px;
        @include transition(all .2s ease);
        .mat-accordion{
            .mat-expansion-panel-header{
                padding:12px 14px;
                height: auto!important;
                .menu-text-icon{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    .material-icons{
                        color:$theme-color;
                        margin-right: 10px;
                    }
                    h3{
                        line-height: normal;
                        font-size: 15px;
                        color:#212446;
                        &:focus{
                            outline: none;
                        }
                    }
                } 
            }
            .mat-expansion-panel-content{
                .mat-expansion-panel-body{
                    padding: 0 14px 0px;
                    mat-list-item{
                        display: block;
                        padding:10px 0;
                        cursor: pointer;
                    }
                    .menu-text-icon{
                        display: flex;
                        align-items: center;
                        .material-icons{
                            color:$theme-color;
                            margin-right: 10px;
                        }
                        h3{
                            line-height: normal;
                            font-size: 15px;
                            color:#212446e3;
                            &:focus{
                                outline: none;
                            }
                        }
                        &.active{
                            h3{
                              color:$theme-color;  
                            }
                        }
                    }
                    .mat-list-base{
                        padding-top: 0px;
                    }
                }
                 
            }
            .mat-expansion-panel:not([class*=mat-elevation-z]) {
                box-shadow: none;
            }
            mat-expansion-panel.mat-expansion-panel-spacing{
                margin:0;
            }
        }
    }
    .brand-sidebar {
        position: fixed;
        left: 0;
        width: 250px;
        @include transition(all .2s ease);
        top: 0px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        z-index: 9;
        background: #fff;
        -webkit-transition: .3s ease all;
        -o-transition: .3s ease all;
        -moz-transition: .3s ease all;
        transition: .3s ease all;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        .logo-wrapper {
            font-size: 36px;
            padding: 6px 10px;
            white-space: nowrap;
            line-height: 17px !important;
            a.brand-logo {
                color: #fff;
                img {
                    height: 51px;
                }
                span.logo-text {
                    font-size: 26px;
                    padding-left: 8px;
                    color: #000;
                    visibility: visible;
                    position: relative;
                    top: -16px;
                    opacity: 1;
                    transition: opacity 0.2s linear;
                }
            }
            a.navbar-toggler {
                position: absolute;
                right: 20px;
                top: 17px;
                mat-icon {
                    font-size: 18px;
                    color: #ff8309;
                }
            }
        }
    }
    app-sidemenu-item{
        .mat-list-base{
            padding-top: 0px!important;
           .mat-list-item {
               &.mat-list-item-with-avatar{
                height: 47px;
                    h3{
                        font-weight: 500;
                        font-size: 15px;
                        line-height: normal;
                    }
                    .mat-list-item-content{
                        padding: 0 8px;
                        .mat-list-text{
                            padding-left: 10px;
                        }
                    }
               }
               .mat-list-item-content{
                    .mat-list-item-ripple + .mat-list-icon{
                        color:$theme-color;
                    }
               }
        } 
    }
    &.sub-menu{
        .mat-list-base{
            .mat-list-item {
                // border-bottom: solid 1px #d3d3d3;
                font-size: 15px;
                &.mat-list-item-with-avatar{
                height:47px;
                     h3{
                         font-weight:400;
                     }
                }
                .mat-list-icon{
                    color:#888888!important;
                }
         } 
     }
    }
    app-sidemenu-item{
        app-sidemenu-item{
            mat-nav-list{
                background:#f2f2f2;
                .mat-list-item{
                    .mat-list-icon{
                        margin-left:15px;
                    }
                }
            }
        }
    }
    }
}
mat-sidenav{
}

mat-sidenav.sidenav .mat-drawer-inner-container{
    overflow: inherit!important
}

/*--short menu icon--*/
mat-sidenav-content{
    &.mat-sidenav-content{
            .mat-drawer-inner-container{
                overflow: inherit;
            }
    }
}

/*--left and right side bar menu--*/



/*--pagination--*/
.pagination-wrap{
    float: left;
    width: 100%;
    padding: 15px 0;
        .toolbar{
            display: flex;
            justify-content:space-between;
                .sorter{
                    display: flex;
                    align-items: center;
                        .sort-by-wrap{
                            position: relative;
                            margin-right: 15px;
                            .sort-by-active{
                                display: flex;
                                align-items: center;
                                border: solid 1px #ccc;
                                padding:0 0px 0 5px; 
                                cursor: pointer;
                            }
                            .short-value{
                                z-index: 9;
                                padding: 5px 0 0 5px;
                                margin: -1px 0 0 0;
                                position: absolute;
                                width: 100%;
                                background: #fff;
                                border: solid 1px #e1e1e1;
                                box-shadow: 0 2px 4px rgba(0,0,0,.1);
                                .mat-list-item{
                                    margin-bottom:3px;
                                    font-size: 14px;
                                    height: auto;
                                        .mat-list-item-content{
                                            padding: 0;
                                        }
                                }
                                    a{
                                        text-decoration: none;
                                        color:#000;
                                    }
                            }
                        }
                        .record-count{
                            color:#888888;
                        }
                }
                .pager{
                    ul{
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        display: flex;
                        align-items: center;
                            li{
                                display: inline-block;
                                vertical-align: middle;
                                    .pagination-items{
                                        li{
                                            a{
                                                padding: 0 5px;
                                                &.active{
                                                    color:$theme-color;
                                                }
                                            }
                                        }
                                    }
                                    &.first.pagination-icon{
                                        border: solid 1px #ccc;
                                        padding: 5px 0 0 7px;
                                        color: #848484;
                                        .material-icons{
                                            font-size: 16px;
                                        }
                                    }
                                    &.last.pagination-icon{
                                        border: solid 1px #ccc;
                                        padding:5px 4px 0 4px;
                                        color: #848484;
                                        .material-icons{
                                            font-size: 16px;
                                        }
                                    }
                            }
                    }
                    .pager-ul-list{
                        display: flex;
                        .mat-list-item{
                            height: auto;
                            display: flex;
                            .mat-list-item-content{
                                padding: 0;
                                .mat-list-base{
                                    padding-top:0px;
                                }
                            }
                           a{
                               color: #000;
                           }
                           i[class*="icon-"]{
                               font-size:10px;
                               color:#666;
                           }
                           &.page-number{
                               a{
                                color: #666;
                                padding: 0 2px;
                                border: solid 1px #666;
                                margin: 0 2px;
                                font-size: 14px;
                                    &.active{
                                        color: $theme-color;
                                        padding: 0 2px;
                                        border: solid 1px $successColor;
                                        margin: 0 2px;
                                        font-size: 14px;
                                    }
                               }
                           }
                        }
                        
                    }

                }
                a{
                    cursor: pointer;
                }
        }
}
/*--pagination--*/



.mat-input-group{
    &.align-center{
        display: flex;
        align-items: center;
    }
} 



.header-bg{
    background:$title-bg-color;
    padding: 10px;
    margin:0 0 15px;
    color:#fff;
}
.high-light-text{
    color:$orange-text;
}

.ancor-text-btn{
    color: $theme-color;
    vertical-align: middle;
    margin-left: 10px;
    text-decoration: none;
    .material-icons{
        vertical-align: middle;
    }
}

i[class*="icon-"]{
    font-size: 18px;
    padding: 0 2px;
}
.routerLink {
    color: $button-color;
    text-decoration: underline;
}

// app-footer{
//     position: absolute;
//     background: #fff;
//     left:0;
//     right: 0;
//     bottom:0;
//     padding: 10px 0;
// }
.otp-form{
    .otp-input.mat-form-field-appearance-legacy{
        .mat-form-field-infix{
            display: inherit;
            border-top: 0px!important;
        }
    }
}

.promotion-card{
    display: flex;
    flex-wrap: wrap;
    .mat-card{
        min-height: 260px;
        transition: box-shadow .25s;
        padding: 24px;
        margin:0 3% 30px 0;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
        width: 31.2%;

        &:nth-child(3n+3){
            margin-right: 0;
        }
        .mat-card-header{
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            margin-bottom: 20px;
            .mat-card-header-text{
                margin: 0;
                .mat-card-title.pro-card-title{
                    margin: 0;
                }
            }
            }
            .mat-card-content{
                font-size: 15px;
                line-height: 22px;
            }
            .mat-card-actions{
                padding: 0;
                margin: 0;
            }
    }

}

.number-picker{
    .mat-icon-button{
        float: left;
    }
    .mat-form-field {
        float: left;
    font-size: 21px;
    position: relative;
    top: -28px;
    left: 8px;
        input{
            color:#bb925c; 
        }
    }
}

ng-number-picker .input-group {
    display: flex;
}
ng-number-picker .input-group-prepend.ng-star-inserted {
    border: 1px solid #ccc;
    width: 35px;
    line-height: 1.5;
    height: 35px;
    text-align: center;
    border-radius: 50px;
    color: #fff;
    background: #bb925c;
}
ng-number-picker  input.form-control {
    border-bottom: 1px dashed #ccc;
    border-right: none;
    border-top: none;
    text-align: center;
    border-left: none;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
    background-color: #bb925c;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(187, 146, 92, 0.54);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
    background-color: #bb925c;  
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
    border-color:#bb925c;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background{
    background-color: #bb925c;
}
.btn-no-wrap{
    white-space: nowrap;
}
.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element{
    background: transparent;
}

/*---image editor---*/

/*---image editor---*/




.lightbox{
    &.animation{
        top: 0;
        left: 0px;
        bottom: 0!important;
        right: 0;
        justify-content: center;
        align-items: center;
        display: flex!important;
        flex-flow: column;
    }
} 

/*--add post text tab--*/
.color-picker{
    left: 40%;
}


/*--full page css--*/
.full-page{
    .heading-top-bg{
    background:$title-bg-color;
    padding: 10px 20px;
        h1{
        color: #fff;
        font-size:20px;
        margin: 0;
        }
    }
    .w-100{
    width:100%;
    }
    .pad-30{
    padding:30px;
    }
    .rating-page-container{
    padding:3% 8%;
        .rating-page-left{
        margin-right:30px;
            .rating-star-container{
            padding:20px;
            box-shadow:1px 1px 4px #ccc;
            margin-bottom:20px;
                h4{
                
                margin-bottom:30px;
                font-size:18px;
                }
                fieldset{
                margin:0;
                padding:0px;
                font-size:20px; 
                }
            }
        }
        h4{
        font-size:18px;
        color:#333;
        }
        .rating-value-amt{
        display:inline-block;
        font-size:18px;
        padding:7px 0 0 10px;
        color:#7e497d
        }
    }  
}

.star.medium, .rating.medium .star{
    width:34px;
    height:34px;
}





/****** Style Star Rating Widget *****/




.rating { 
  border: none;
  float: left;
}

.rating > input { display: none; } 
.rating > label:before { 
  margin: 5px;
  font-size: 1.25em;
  font-family:revugainfont;
  display: inline-block;
  /*-content: "\f005";*/
  content: "\0042";
  
}

.rating > .half:before { 
  /*-content: "\f089";-*/
  content: "\0041";
  position: absolute;
  margin: 5px 0 0 -1px;
}

.rating > label { 
  color: #ddd; 
 float: right; 
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */

.rating:not(:checked) > label:hover ~ label { color: #bb925c;  } /* hover previous stars in list */

.rating:not(:checked) > label:hover {color:#7e497d}/* hover current star */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label { color: #7e497d;  }

.or-option{
    text-align: center;
     span:first-child{
        background: #fff;
        border-radius: 100%;
        padding: 5px;
        display: inline-block;
        line-height: 12px;
        height: 22px;
        width: 22px;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
     }
}
.open-option-btn{
    border: solid 1px #ffff!important;
    color: #fff;
    margin: 8px 0 10px!important;
    width: 100%;
    border-radius: 30px !important;

}

.text-area-height {
    min-height: 120px;
}

.p-3 {
    padding: 10px !important;
}
.pt-3 {
  padding-top: 10px !important;
}
.pb-3 {
  padding-bottom: 10px !important;
}
.m-3 {
    margin: 10px !important;
}
.mt-3 {
    margin-top:10px !important;
}
.light-purple {
    background: #b9c6e8;
}
.light-yellow {
    background: #dbe1d5;
}
.light-blue {
    background: #bde5e9;
}
.icon-size {
    font-size: 20px;
}
.icon-color-violet {
    color: #7e497d;
}
.f-right {
    float: right;
}

.float-none .pagination-wrap {
    float: none !important;
}
mat-card-title {
    word-break: break-all;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.mat-form-field-appearance-outline.white-bdr-input {
    .mat-form-field-outline-start{
        border-top:1px solid #fff;
        border-bottom:1px solid #fff;
        border-left: solid 1px #fff;
    }
    .mat-form-field-outline-gap{
        border-top:1px solid #fff;
        border-bottom:1px solid #fff;
    }
    .mat-form-field-outline-end{
        border-top:1px solid #fff;
        border-bottom:1px solid #fff;
        border-right: solid 1px #fff;
    }
    .mat-select-arrow{
        color: #fff;
    }
    .mat-select-value{
        color: #fff
    }
    .mat-form-field-label{
        color:#fff;
    }
}
.form-d-inherit{
    display: inherit!important;
}

/*---angular text editor css--*/
.angular-editor-toolbar{
    .angular-editor-toolbar-set{
        button{
            &#strikeThrough-,&#subscript-,&#superscript-,&#foregroundColorPicker-,&#backgroundColorPicker-,&#toggleEditorMode-{
                display:none;
            }
        }
        select{
            &#heading-,&#fontSelector-{
                display: none;
            }
        }
        button.angular-editor-button[title~="Clear"]{
            display: none;
        }
        button.angular-editor-button[title~="Image"]{
            display: none;
        }
        button.angular-editor-button[title~="Horizontal"]{
            display: none;
        }
    }
}

/*---Hiding pagination from import customer page */
.import-excel app-pagination {
    display: none !important;
}

#mat-tab-content-0-0 .mat-tab-body-content {
    overflow: hidden !important;
}
#mat-tab-content-0-1 .mat-tab-body-content {
    overflow: hidden !important;
}

.search-results {
    overflow-y: scroll; 
    max-height: 80%;
  }

  .anchor-color {
      color: inherit !important;
      display: inline-flex;
  }
  .no-grow {
      flex-grow: unset !important;
  }

/*---angular text editor css--*/
/*--- media query start here---*/
@media screen and (max-width:1024px) {
   
}
@media screen and (max-width:960px) {
    .mat-dialog-box.point-view .mat-dialog-content .point-view-maindiv{
        height: auto;
    }
    .cashier-layout .cashier-full-hw-layout{
        height: auto;;
    }

    
}
@media screen and (max-width:768px) {
    .pointviewcol-first{
        padding:40px!important;
    }
}

@media screen and (max-width:599px) {
    .cashier-header .logo-wrapper .brand-logo .logo-text{
        display: none!important;
    }
    .cashier-header .cashier-top-links a{
        padding: 0 5px!important;
    }
    .toolbar-user-container .dropdown{
        min-width: 125px!important;
    }
    .cashier-header .brand-sidebar{
        flex: 0 1 auto!important;
    }
    .full-page .rating-page-container .rating-page-left {
        margin-right: 0px;
    }
}

/*--- media query start here---*/

app-customers .pagination-wrap .toolbar .sorter .sort-by-wrap .short-value {
    min-width: 70px;
}


.non-app-url-title{
    background:$title-bg-color;
    color: #fff;
    font-size: 20px;
    padding: 10px 20px;
    margin: 0;
}
.non-app-url-title2 {
    /* background: #efe8e8; */
    color: #333;
    font-size: 14px;
    box-shadow: 1px 1px 7px 1px #ccc;
    /* border-bottom: 1px solid #ccc; */
    padding: 10px 20px;
    margin: 0;
}
