/* Generated by Glyphter (http://www.glyphter.com) on  Wed Mar 27 2019*/
@font-face {
    font-family: 'revugainfont';
    src: url('../fonts/revugainfont/revugainfont.eot');
    src: url('../fonts/revugainfont/revugainfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/revugainfont/revugainfont.woff') format('woff'),
         url('../fonts/revugainfont/revugainfont.ttf') format('truetype'),
         url('../fonts/revugainfont/revugainfont.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'revugainfont';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-star:before{content:'\0041';}
.icon-star-half:before{content:'\0042';}
.icon-arrow-left:before{content:'\002a';}
.icon-arrow-right:before{content:'\002b';}
.icon-menu-bar:before{content:'\002c';}
.icon-slide-menu:before{content:'\002d';}
.icon-thumb:before{content:'\002e';}
.icon-chat:before{content:'\002f';}
.icon-add-user:before{content:'\005b';}
.icon-user:before{content:'\005c';}
.icon-tag:before{content:'\005d';}
.icon-tag-star:before{content:'\005e';}
.icon-offer:before{content:'\005f';}
.icon-home:before{content:'\0060';}
.icon-graph:before{content:'\007b';}
.icon-edit:before{content:'\007c';}
.icon-setting:before{content:'\007e';}
.icon-arrow-thin-right:before{content:'\003f';}
.icon-arrow-thin-left:before{content:'\0057';}
.icon-arrow-thin-top:before{content:'\0058';}
.icon-arrow-thin-dowm:before{content:'\0059';}
.icon-bell:before{content:'\005a';}
.icon-calender:before{content:'\0061';}
.icon-announce:before{content:'\0062';}
.icon-arrow-circle-left:before{content:'\0063';}
.icon-arrow-circle-right:before{content:'\0064';}
.icon-copy:before{content:'\0065';}
.icon-arrow-double-left:before{content:'\0066';}
.icon-arrow-double-right:before{content:'\0067';}
.icon-message:before{content:'\0068';}
.icon-info:before{content:'\0069';}
.icon-in-out:before{content:'\006a';}
.icon-favourite:before{content:'\006b';}
.icon-attach:before{content:'\006c';}
.icon-location:before{content:'\006d';}
.icon-mail-out:before{content:'\006e';}
.icon-setting:before{content:'\006f';}
.icon-download:before{content:'\0070';}
.icon-chat:before{content:'\0071';}
.icon-edit:before{content:'\0072';}
.icon-facebook:before{content:'\0073';}
.icon-twitter:before{content:'\0074';}
.icon-youtube:before{content:'\0075';}
.icon-dashboard:before{content:'\0076';}
.icon-google-p:before{content:'\0077';}
.icon-instagram:before{content:'\0078';}
.icon-slide-right:before{content:'\0079';}
.icon-delete:before{content:'\007a';}
.icon-add:before{content:'\0030';}
.icon-filter:before{content:'\0031';}
.icon-file:before{content:'\0032';}
.icon-full-width:before{content:'\0033';}
.icon-building:before{content:'\0034';}
.icon-close:before{content:'\0035';}
.icon-master:before{content:'\0036';}
.icon-users:before{content:'\0037';}
.icon-calculater:before{content:'\0038';}
.icon-crm:before{content:'\0039';}
.icon-master-settin:before{content:'\0021';}
.icon-book:before{content:'\0022';}
.icon-chart:before{content:'\0023';}
.icon-main-menu:before{content:'\0024';}
.icon-time:before{content:'\0025';}
.icon-name:before{content:'\0026';}
.icon-name:before{content:'\0027';}